import * as React from "react"
import { mainStyles, divText } from './politica-de-privacidad.module.css'
import Footer from "../../components/Footer"
import NavBar from "../../components/NavBar"
import { Helmet } from "react-helmet"

const IndexPage = () => {
  return (
    <div className={mainStyles}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Política de privacidad - Fede Cocina</title>
          <meta name="description" content="POLÍTICA DE PRIVACIDAD Si estás aquí es porque quieres saber más sobre las obligaciones y derechos que te corresponden"/>
          <html lang="es"/>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8506919019942528" crossorigin="anonymous"></script>
        </Helmet>
      <NavBar></NavBar>
      <div className={divText}>
        <p><strong>POLÍTICA DE PRIVACIDAD</strong></p>
        <p>El presente Política de Privacidad establece los términos en que Fede Cocina usa y protege la información que es proporcionada por sus usuarios al momento de utilizar su sitio web. Esta compañía está comprometida con la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.</p>
        <p><strong>Información que es recogida</strong></p>
        <p>Nuestro sitio web podrá recoger información personal por ejemplo: Nombre,&nbsp; información de contacto como&nbsp; su dirección de correo electrónica e información demográfica. Así mismo cuando sea necesario podrá ser requerida información específica para procesar algún pedido o realizar una entrega o facturación.</p>
        <p><strong>Uso de la información recogida</strong></p>
        <p>Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible, particularmente para mantener un registro de usuarios, de pedidos en caso que aplique, y mejorar nuestros productos y servicios. &nbsp;Es posible que sean enviados correos electrónicos periódicamente a través de nuestro sitio con ofertas especiales, nuevos productos y otra información publicitaria que consideremos relevante para usted o que pueda brindarle algún beneficio, estos correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.</p>
        <p>Fede Cocina está altamente comprometido para cumplir con el compromiso de mantener su información segura. Usamos los sistemas más avanzados y los actualizamos constantemente para asegurarnos que no exista ningún acceso no autorizado.</p>
        <p><strong>Cookies</strong></p>
        <p>Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su ordenador, al aceptar dicho fichero se crea y la cookie sirve entonces para tener información respecto al tráfico web, y también facilita las futuras visitas a una web recurrente. Otra función que tienen las cookies es que con ellas las web pueden reconocerte individualmente y por tanto brindarte el mejor servicio personalizado de su web.</p>
        <p>Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta información es empleada únicamente para análisis estadístico y después la información se elimina de forma permanente. Usted puede eliminar las cookies en cualquier momento desde su ordenador. Sin embargo las cookies ayudan a proporcionar un mejor servicio de los sitios web, estás no dan acceso a información de su ordenador ni de usted, a menos de que usted así lo quiera y la proporcione directamente. Usted puede aceptar o negar el uso de cookies, sin embargo la mayoría de navegadores aceptan cookies automáticamente pues sirve para tener un mejor servicio web. También usted puede cambiar la configuración de su ordenador para declinar las cookies. Si se declinan es posible que no pueda utilizar algunos de nuestros servicios.</p>
        <p><strong>Google Analytics</strong></p>
        <p>Google Analytics es un servicio analítico de web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”).</p>
        <p>Google Analytics utiliza “cookies”, que son archivos de texto ubicados en tu ordenador, para ayudar al website a analizar el uso que hacen los usuarios del sitio web.</p>
        <p>La información que genera la cookie acerca de tu uso del website (incluyendo tu dirección IP) será directamente transmitida y archivada por Google. Google usará esta información por cuenta nuestra con el propósito de seguir la pista de su uso del website, recopilando informes de la actividad del website y prestando otros servicios relacionados con la actividad del website y el uso de Internet.</p>
        <p>Google podrá transmitir dicha información a terceros cuando así se lo requiera la legislación, o cuando dichos terceros procesen la información por cuenta de Google. Google no asociará su dirección IP con ningún otro dato del que disponga.</p>
        <p>Como usuario, y en ejercicio de tus derechos, puedes rechazar el tratamiento de los datos o la información rechazando el uso de cookies mediante la selección de la configuración apropiada de tu navegador, sin embargo, debes saber que si lo hace puede que no puedas usar la plena funcionabilidad de este sitio web.</p>
        <p>Al utilizar esta web, de acuerdo a la información facilitada en esta Política de Privacidad, aceptas el tratamiento de datos por parte de Google en la forma y para los fines indicados.</p>
        <p>Para más información, puedes consultar la política de privacidad de Google en https://www.google.com/intl/es/policies/privacy/.</p>
        <p><strong>Enlaces a Terceros</strong></p>
        <p>Este sitio web pudiera contener en laces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en estos enlaces y abandone nuestra página, ya no tenemos control sobre al sitio al que es redirigido y por lo tanto no somos responsables de los <a href="https://plantillaterminosycondicionestiendaonline.com/" target="_blank" rel="noreferrer">términos o privacidad</a> ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.</p>
        <p><strong>Control de su información personal</strong></p>
        <p>Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con un orden judicial.</p>
        <p>Fede Cocina Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.</p>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default IndexPage
